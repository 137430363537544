const btc = require('./coins/btc.json')
const eth = require('./coins/eth.json')
const xrp = require('./coins/ripple.json')
const bch = require('./coins/bch.json')
const ltc = require('./coins/ltc.json')
const omg = require('./coins/omg.json')
const xym = require('./coins/xym.json')
const xem = require('./coins/xem.json')
const dot = require('./coins/dot.json')
const enj = require('./coins/enj.json')
const ada = require('./coins/ada.json')
const link = require('./coins/link.json')
const atom = require('./coins/atom.json')

module.exports = {btc, eth, xrp, bch, ltc, ada, xem, xym, dot, link, enj, omg, atom}


